<template>
  <div class="md-layout text-center">
    <div
      class="md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100"
    >
      <login-card header-color="green">
        <h3 slot="title" class="title">{{ $t('label.app_titul') }}</h3>

        <p slot="description" class="description">{{ $t('login.title') }}</p>
        <div slot="langs" class="lang-buttons">
          <md-button
            v-for="locale in locales"
            :key="locale"
            @click="switchLocale(locale)"
            class="md-primary md-just-icon ms-sm"
            :class="{'md-simple': locale !== activeLocale}"
          >
            {{ locale }}
          </md-button>
        </div>
        <form slot="form" @submit.prevent="onEnter">
          <md-field class="md-form-group" slot="inputs">
            <md-icon>face</md-icon>
            <label>{{ $t('login.user_login') }}</label>
            <md-input v-model="login"></md-input>
          </md-field>
          <md-field
            class="md-form-group"
            slot="inputs"
            :class="{'md-invalid': authStatus === 'error'}"
          >
            <md-icon>lock_outline</md-icon>
            <label>{{ $t('login.user_password') }}</label>
            <md-input v-model="password" type="password"></md-input>
            <span class="md-error">{{ $t('label.auth_error') }}</span>
          </md-field>
          <div class="md-layout">
            <div class="md-layout-item">
              <md-button
                slot="footer"
                class="md-simple md-success md-lg"
                type="submit"
              >
                {{ $t('login.enter') }}
              </md-button>
            </div>
          </div>
          <a href @click.stop.prevent="onPassModalShow" class="md-caption">
            {{ $t('label.lnkPassRec') }}
          </a>
        </form>
      </login-card>
    </div>
    <modal
      v-if="passwdModal"
      @close="passwdModalHide"
      class="small-alert-modal"
    >
      <template slot="header">
        <h4 class="modal-title">{{ $t('label.lnkPassRec') }}</h4>
        <md-button
          class="md-simple md-just-icon md-round modal-default-button"
          @click="passwdModalHide"
        >
          <md-icon>clear</md-icon>
        </md-button>
      </template>

      <template slot="body">
        <p>{{ $t('label.forgot_pass_descr') }}</p>
        <md-field>
          <label>{{ $t('label.email') }}</label>
          <md-input v-model="reqEmail"></md-input>
        </md-field>
      </template>

      <template slot="footer">
        <md-button class="md-success" @click="onReqPassRecovery">
          {{ $t('buttons.send') }}
        </md-button>
      </template>
    </modal>
  </div>
</template>
<script>
import {LoginCard, Modal} from '@/components'
import Swal from 'sweetalert2'
import {mapState} from 'vuex'

export default {
  components: {
    LoginCard,
    Modal
  },
  data() {
    return {
      login: null,
      password: null,
      passwdModal: false,
      reqEmail: ''
    }
  },
  created() {
    this.$store.commit('INIT_LANG')
  },
  methods: {
    passwdModalHide() {
      this.passwdModal = false
    },
    onPassModalShow() {
      this.passwdModal = true
      this.regEmail = ''
    },
    async onReqPassRecovery() {
      let sendResult = this.$t('login.recovery_mail_was_sent')
      let alertType = 'success'
      if (!this.reqEmail) {
        return
      }
      this.passwdModalHide()
      try {
        await this.$store.dispatch('PASSWORD_RECOVERY', this.reqEmail)
      } catch (err) {
        alertType = 'error'
        sendResult = `${this.$t('login.recovery_mail_was_not_sent')} ${err}`
      } finally {
        Swal.fire({
          title: '',
          text: sendResult,
          type: alertType,
          confirmButtonClass: 'md-button md-success btn-fill',
          buttonsStyling: false
        })
      }
    },
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName]

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },
    onEnter() {
      this.errMessage = ''
      const {login, password} = this
      this.$store.dispatch('LOGIN', {login, password}).then(() => {
        if (this.authStatus === 'success') {
          //this.$store.dispatch('GET_MY_PROFILE').then(() => {
          this.$store.dispatch('LOAD_UI_TRANSLATE', this.activeLocale)
          this.$router.push(`home`)
          //})
        } else if (this.authStatus === 'error') {
          this.errMessage = 'message.authError'
          this.password = ''
        }
      })
    },
    switchLocale(code) {
      this.$store.commit('CHANGE_LANG', code)
    }
  },
  computed: {
    ...mapState({
      me: (state) => state.Login,
      authStatus: (state) => state.Login.authStatus,
      localeList: (state) => state.i18nStore.locales
      //activeLocale: (state) => state.i18nStore.active
    }),
    locales() {
      return this.localeList.map((locale) => locale.code) //Object.keys(this.$i18n.messages)
    },
    activeLocale() {
      return this.$i18n.locale
    }
  }
}
</script>

<style lang="scss">
.mp-invalid {
  color: red;
}
.modal-container {
  color: #3c4858;

  margin: 0 auto;
}
.lang-buttons {
  display: flex;
  justify-content: flex-end;
  .md-button {
    font-size: 16px;
  }
}
.description {
  padding-top: 10px;
}
</style>
